/// Secondary
///
@mixin btn--secondary {
    // Vars

    // Support

    // Module

    // Facets

    // States
    & {
        // Base
        @include btn--apply-states(()) {
            @include btn--secondary-base;
        }

        // Focus
        @include btn--apply-states(('disabled': false, 'focus': true)) {
            @include btn--secondary-focus;
        }

        // Active
        @include btn--apply-states(('focus': default, 'active': true)) {
            @include btn--secondary-active;
        }

        // Disabled
        @include btn--apply-states(('disabled': true, 'focus': default)) {
            @include btn--secondary-disabled;
        }
    }
}

/// Base
///
@mixin btn--secondary-base {
    color: $c--brand-gottfried;
    background-color: $c--monochrome-white;
    border-color: $c--monochrome-white;
}

/// Focus
///
@mixin btn--secondary-focus {
    background-color: $c--monochrome-paper-white;
    border-color: $c--monochrome-paper-white;
}

/// Active
///
@mixin btn--secondary-active {
    color: $c--monochrome-dark-gray;
    background-color: $c--monochrome-paper-white;
    border-color: $c--monochrome-paper-white;
}

/// Disabled
///
@mixin btn--secondary-disabled {
    color: $c--monochrome-light-gray;
    background-color: $c--monochrome-white;
    border-color: $c--monochrome-white;
}
