$hamburger-layer-border-radius: 0;
$hamburger-padding-x: 0;
$hamburger-padding-y: 0;
$hamburger-layer-spacing: 7px;
$hamburger-layer-width: 24px;
$hamburger-layer-height: 2px;
$hamburger-hover-opacity: 1;
$hamburger-hover-transition-duration: 200ms;
$hamburger-layer-color: currentColor;
$hamburger-types: (squeeze);

@import 'node_modules/hamburgers/_sass/hamburgers/hamburgers';
