.mobile-switcher {
    // Vars
    $sz--full-size: 38px;
    $sz--button-size: 32px;
    $sz--border-size: 2px;

    // Support
    @mixin mobile-switcher--active-btn() {
        @include has-focus(default) {
            color: $c--monochrome-white;
            cursor: default;
        }
    }

    // Module
    & {
        @include grid--root((row: 'buttons', cols: ('button')));
        @include grid--space((gutter: ($sz--border-size, $sz--border-size), row: 'buttons', cols: ('button')));

        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        align-items: center;
        height: $sz--main-menu-height;

        @include mq('>md') {
            display: none;
        }

        .mobile-switcher--element {
            border: $sz--border-size solid $c--brand-gottfried;
            padding: $sz--border-size;
            border-radius: $sz--full-size;
        }

        .mobile-switcher--element-wrap {
            position: relative;
            display: flex;
            flex-flow: row nowrap;
            justify-content: center;
            align-items: center;
        }

        .mobile-switcher--btn {
            display: flex;
            flex-flow: row nowrap;
            justify-content: center;
            align-items: center;
            width: $sz--button-size;
            height: $sz--button-size;
            border-radius: $sz--button-size;
            color: $c--brand-gottfried;
            cursor: pointer;
            transition: color $trs--base, background-color $trs--base;

            @include has-focus(true) {
                background-color: rgba($c--brand-gottfried, 0.2);
            }
        }

        .mobile-switcher--active-bg {
            $sz: $sz--full-size - ($sz--border-size * 3);

            pointer-events: none;
            position: absolute;
            top: 0;
            left: 0;
            display: block;
            width: $sz;
            height: $sz;
            border-radius: $sz;
            background-color: $c--brand-gottfried;
            box-shadow: $shadow-level-1;
            transition: transform $trs--base;
        }
    }

    // Facets
    & {
        // On list
        &.mobile-switcher__on-list {
            .mobile-switcher--active-bg {
                transform: none;
            }

            .mobile-switcher--btn.mobile-switcher--btn__list {
                @include mobile-switcher--active-btn;
            }
        }

        // On map
        &.mobile-switcher__on-map {
            .mobile-switcher--active-bg {
                transform: translateX(100%) translateX($sz--border-size);
            }

            .mobile-switcher--btn.mobile-switcher--btn__map {
                @include mobile-switcher--active-btn;
            }
        }
    }

    // States
}
