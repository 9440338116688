.menu-sidebar {
    // Vars

    // Support

    // Module
    & {
        @include grid--root((row: 'footer-items', cols: ('footer-item')));
        @include grid--space((gutter: (7px 49px 3px), row: 'footer-items', cols: ('footer-item')));

        display: flex;
        flex-flow: column nowrap;
        width: $sz--menu-sidebar-width;
        padding-top: $sz--main-menu-height;
        background-color: $c--brand-gottfried;

        @include mq('<=md') {
            width: 100%;
        }

        .menu-sidebar--main {
            display: flex;
            flex-flow: column nowrap;
            justify-content: center;
            flex: 0 1 100%;
            padding: 16px;
        }

        .menu-sidebar--link {
            @include type--cta-large;
            display: block;
            color: $c--monochrome-white;
            text-align: center;
            transition: color $trs--base;

            @include has-focus(true) {
                color: mix($c--monochrome-white, $c--brand-gottfried, 80%);
            }

            &.menu-sidebar--link__footer {
                @include type--cta-small;
            }
        }

        .menu-sidebar--snake-pattern {
            margin: 14px auto 18px;
            color: $c--monochrome-white;
        }

        .menu-sidebar--footer {
            padding: 16px 32px;
        }

        .menu-sidebar--footer-items {
            display: flex;
            flex-flow: row nowrap;
            justify-content: center;
        }

        .menu-sidebar--footer-item {
            ~ .menu-sidebar--footer-item {
                border-left: 1px solid $c--monochrome-white;
            }
        }
    }

    // Facets

    // States
}
