/// Web-static folder
///
$web-static: '../..';

/// BEM options
///
$bem-element-separator: '--';
$bem-modifier-separator: '__';

/// Media queries and breakpoints
/// See: `util/mq.scss`

/// Colors
/// See: `util/colors.scss`

/// Layers (z-index)
///
$layers: (
    cms-main-menu: 7,
    hamburger: 7,
    home-link: 7,
    mobile-switcher: 7,
    main-menu-mobile: 7,
    overlay: 6,
    menu-sidebar: 6,
    main-menu: 5,
    home-overlay: 4,
    gallery-overlay: 4,
    main-sidebar: 3,
    sub-sidebar: 2,
    background-map: 1,
    content: 0,
);

/// Fonts
///
$fz--medium: 1.8rem;
$fz--small: 1.5rem;
$fz--x-small: 1.2rem;

$lh--medium: (2.6rem / $fz--medium);
$lh--small: (1.8rem / $fz--small);
$lh--x-small: (1.44rem / $fz--x-small);

/// Sizes & spacing
///
$sz--list-icon-width: 12px;
$sz--list-icon-gutter: 6px;
$sz--list-padding: ($sz--list-icon-width + $sz--list-icon-gutter);
$sz--list-vertical-spacing: 8px;

$sz--snake-pattern-width: 54px;
$sz--snake-pattern-height: 6px;

$sz--main-menu-height: 60px;
$sz--menu-sidebar-width: 400px;
$sz--entry-teaser-height: 100px;

/// Transitions & animations
///
$trs--base-speed: 200ms;
$trs--base-fn: ease;
$trs--base: $trs--base-speed $trs--base-fn;

$trs--home-overlay-speed: 400ms;
$trs--home-overlay-fn: ease;
$trs--home-overlay: $trs--home-overlay-speed $trs--home-overlay-fn;

$trs--common-property-list: color, border-color, background-color, opacity, transform;
$trs--common-props: transition-props($trs--base, $trs--common-property-list...);

/// Border radius
///
$sz--border-radius-base: 0;
$sz--border-width-base: 2px;
