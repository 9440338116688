/// Primary
///
@mixin btn--primary {
    // Vars

    // Support

    // Module

    // Facets

    // States
    & {
        // Base
        @include btn--apply-states(()) {
            @include btn--primary-base;
        }

        // Focus
        @include btn--apply-states(('disabled': false, 'focus': true)) {
            @include btn--primary-focus;
        }

        // Active
        @include btn--apply-states(('focus': default, 'active': true)) {
            @include btn--primary-active;
        }

        // Disabled
        @include btn--apply-states(('disabled': true, 'focus': default)) {
            @include btn--primary-disabled;
        }
    }
}

/// Base
///
@mixin btn--primary-base {
    @include ff--base-bold;
    color: $c--monochrome-white;
    background-color: $c--brand-gottfried;
    border-radius: 2px;
    border-color: transparent;
    letter-spacing: 0.1rem;
    text-transform: uppercase;
    box-shadow: $shadow-level-1;
}

/// Focus
///
@mixin btn--primary-focus {
    background-color: color-shade($c--brand-gottfried, 20%);
}

/// Active
///
@mixin btn--primary-active {
    color: $c--monochrome-light-gray;
    background-color: $c--monochrome-black-gray;
}

/// Disabled
///
@mixin btn--primary-disabled {
    color: $c--monochrome-silver;
    background-color: $c--monochrome-light-gray;
}
