.main-category-menu {
    // Vars
    $sz--height: 180px;
    $sz--padding: 12px;

    // Support

    // Module
    & {
        @include grid--root((row: 'items', cols: ('item')));
        @include grid--space((gutter: ($sz--padding, $sz--padding), row: 'items', cols: ('item'), mq: '>md'));
        position: relative;
        width: 100%;
        height: $sz--height;

        .main-category-menu--btn {
            display: block;
            width: 100%;
            height: 100%;
        }

        .main-category-menu--overlay {
            z-index: 1;
            position: absolute;
            top: 100%;
            left: 0;
            width: 100%;
            padding: $sz--padding;
            background-color: $c--monochrome-white;
            background-image: linear-gradient(to left, $c--monochrome-light-gray 0, transparent 1px, transparent 2px);

            @include mq('<=md') {
                position: fixed;
                top: 0;
                left: 0;
                display: flex;
                flex-flow: row nowrap;
                align-items: stretch;
                height: 100%;
                width: 100%;
                padding: $sz--main-menu-height 6px 6px 6px;
            }
        }

        .main-category-menu--items {
            flex-flow: row wrap;
        }

        .main-category-menu--item {
            @include grid--span((ratio: 1 / 2));
            overflow: visible;

            @include mq('<=md') {
                height: 50%;
            }
        }
    }

    // Facets

    // States
}
