.entry-teaser {
    // Vars
    $sz--bullet: 8px;
    $sz--arrow-offset: 20px;

    // Support

    // Module
    & {
        @include grid--root((row: 'bullets', cols: ('bullet')));
        @include grid--space((gutter: (6px, 6px), row: 'bullets', cols: ('bullet')));
        transition: $trs--common-props;

        .entry-teaser--link {
            position: relative;
            display: flex;
            flex-flow: row nowrap;
            width: 100%;
            height: $sz--entry-teaser-height;

            @include has-focus(false) {
                .entry-teaser--arrow {
                    opacity: 0;
                    transform: translateX(-$sz--arrow-offset);
                }
            }

            @include has-focus(true) {
                .entry-teaser--arrow {
                    opacity: 1;
                    transform: translateX(0);
                }
            }
        }

        .entry-teaser--content {
            display: flex;
            flex-flow: column nowrap;
            flex: 0 1 100%;
        }

        .entry-teaser--title {
            @include type--h2;
            flex: 0 1 100%;
            padding: 12px 0 0 16px;

            @include mq('<=lg') {
                font-size: 1.6rem;
                line-height: $lh--medium;
            }
        }

        .entry-teaser--bullets {
            flex: 0 0 auto;
            padding: 16px;
        }

        .entry-teaser--bullet {
            display: block;

            @each $color-key, $color-value in $brands {
                &.entry-teaser--bullet__main-category-facet-#{$color-key} {
                    .entry-teaser--bullet-icon {
                        background-color: $color-value;
                    }
                }
            }
        }

        .entry-teaser--bullet-icon {
            display: block;
            width: $sz--bullet;
            height: $sz--bullet;
            border-radius: 50%;
        }

        .entry-teaser--arrow {
            display: flex;
            flex-flow: row nowrap;
            justify-content: center;
            align-items: center;
            padding: 0 12px;
            color: $c--brand-gottfried;
            transition: transform $trs--base $trs--base-speed, opacity $trs--base $trs--base-speed;
        }

        .entry-teaser--close {
            position: absolute;
            top: 50%;
            right: 0;
            transform: translateY(-50%);
            height: 100%;
        }

        .entry-teaser--close-btn {
            height: 100%;
            padding: 0 8px;
            color: $c--brand-gottfried;
            cursor: pointer;
            transition: color $trs--base;

            @include has-focus(true) {
                color: $c--label-text;
            }
        }
    }

    // Facets

    // States
    & {
        // Is active entry
        &.entry-teaser__is-active-entry {
            .entry-teaser--link {
                @include has-focus(default) {
                    cursor: default;

                    .entry-teaser--arrow {
                        opacity: 0;
                        transform: translateX($sz--arrow-offset);
                        transition: transform $trs--base, opacity $trs--base;
                    }
                }
            }
        }

        // Main sidebvar
        &.entry-teaser__main-sidebar {
            &.entry-teaser__is-active-entry {
                $color: mix($c--monochrome-paper-white, $c--monochrome-white, 20%);

                .entry-teaser--link {
                    border-right: 1px solid transparent;
                    background-color: $color;
                    background-clip: content-box;
                }
            }
        }

        // In entry content`
        &.entry-teaser__in-entry-content {
            .entry-teaser--title {
                padding-left: 0;
                font-size: 1.9rem;

                @include mq('<=lg') {
                    font-size: 1.6rem;
                    line-height: $lh--medium;
                }
            }

            .entry-teaser--bullets {
                padding-left: 0;
                padding-right: 0;
            }

            .entry-teaser--arrow {
                padding-right: 0;
            }
        }

        // In CMS
        &.entry-teaser__in-cms {
            @include mq('xs') {
                .entry-teaser--link {
                    height: auto;
                }
            }
        }
    }
}
