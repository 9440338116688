.ui-overlay {
    // Vars

    // Support

    /// Handle the overlay's scrolling behaviour
    /// @param {boolean} $allow-native-scrolling - Use native scrolling on the component or leave it be as is
    ///
    @mixin ui-overlay--restrict-scrolling-to-element($allow-native-scrolling: true) {
        .ui-overlay--display {
            overflow: hidden;
        }

        @if ($allow-native-scrolling) {
            .ui-overlay--component {
                @include ios-native-scrolling;
            }
        }
    }

    /// Handle the overlay's interactivity
    /// @param {boolean} $allow-backdrop - Allow or disallow click events on the backdrop (prevents clickthrough)
    /// @param {boolean} $allow-backdrop-click-area - Allow or disallow click events on the backdrop click area (allows closing the overlay)
    ///
    @mixin ui-overlay--restrict-pointerevents-to-element($allow-backdrop: false, $allow-backdrop-click-area: false) {
        pointer-events: none;

        .ui-overlay--component {
            pointer-events: auto;
        }

        @if ($allow-backdrop) {
            .ui-overlay--backdrop {
                pointer-events: auto;
            }
        }

        @if ($allow-backdrop-click-area) {
            .ui-overlay--backdrop-click-area {
                pointer-events: auto;
            }
        }
    }

    /// Handle the overlay's z-index
    /// @param {string} $layer - Use a layer name to set a z-index
    /// @param {number} $z-index - Directly use a `z-index`
    ///
    @mixin ui-overlay--set-layer-hierarchy($layer: null, $z-index: 0) {
        .ui-overlay--root {
            z-index: if($layer, z-index($layer), $z-index);
        }
    }

    /// Remove the overlay's (visual) backdrop
    ///
    @mixin ui-overlay--hide-backdrop() {
        .ui-overlay--backdrop {
            display: none;
        }
    }

    /// Allow the overlay to be full height
    ///
    @mixin ui-overlay--full-height() {
        .ui-overlay--display,
        .ui-overlay--wrap-outer,
        .ui-overlay--wrap-inner,
        .ui-overlay--container,
        .ui-overlay--revealer {
            display: flex;
            flex-flow: row nowrap;
            align-items: stretch;
        }
    }

    /// Allow the overlay to be full width
    ///
    @mixin ui-overlay--full-width() {
        .ui-overlay--wrap-inner,
        .ui-overlay--container,
        .ui-overlay--revealer {
            flex: 0 1 100%;
            max-width: 100%;
        }
    }

    // Module
    & {
        .ui-overlay--root {
            position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
        }

        .ui-overlay--backdrop {
            position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background-color: rgba($c--monochrome-black, 0.2);
        }

        .ui-overlay--display {
            @include ios-native-scrolling;
            position: relative;
            width: 100%;
            height: 100%;
        }

        .ui-overlay--backdrop-click-area {
            @include overlay;
            z-index: 1;
        }

        .ui-overlay--wrap-outer {
            @include is-selectable(true);
            display: table;
            width: 100%;
            height: 100%;
            table-layout: fixed;
        }

        .ui-overlay--wrap-inner {
            position: relative;
            display: table-cell;
            vertical-align: middle;
            text-align: center;
        }

        .ui-overlay--container {
            z-index: 2;
            position: relative;
            width: 100%;
        }

        .ui-overlay--revealer {
            display: flex;
            flex-flow: row nowrap;
            justify-content: center;
            text-align: left;
        }
    }

    // Facets
    & {
        // base
        &.ui-overlay__base {
            @include ui-overlay--set-layer-hierarchy($layer: 'overlay');
        }

        // Home overlay
        &.ui-overlay__home-overlay {
            @include ui-overlay--hide-backdrop;
            @include ui-overlay--full-height;
            @include ui-overlay--full-width;
            @include ui-overlay--restrict-scrolling-to-element;
            @include ui-overlay--restrict-pointerevents-to-element($allow-backdrop-click-area: true);
            @include ui-overlay--set-layer-hierarchy($layer: 'home-overlay');

            .ui-overlay--revealer {
                justify-content: flex-end;
            }
        }

        // Gallery overlay
        &.ui-overlay__gallery-overlay {
            @include ui-overlay--hide-backdrop;
            @include ui-overlay--full-height;
            @include ui-overlay--full-width;
            @include ui-overlay--restrict-scrolling-to-element($allow-native-scrolling: true);
            @include ui-overlay--restrict-pointerevents-to-element($allow-backdrop-click-area: true);
            @include ui-overlay--set-layer-hierarchy($layer: 'gallery-overlay');

            .ui-overlay--revealer {
                width: 100%;
                overflow: hidden;
            }
        }

        // Side bar
        &.ui-overlay__menu-sidebar {
            @include ui-overlay--hide-backdrop;
            @include ui-overlay--full-height;
            @include ui-overlay--restrict-scrolling-to-element;
            @include ui-overlay--restrict-pointerevents-to-element($allow-backdrop-click-area: true);
            @include ui-overlay--set-layer-hierarchy($layer: 'menu-sidebar');

            @include mq('<=md') {
                @include ui-overlay--full-width;

                .ui-overlay--component {
                    overflow: auto;
                }
            }

            .ui-overlay--container {
                justify-content: flex-end;
            }

            .ui-overlay--wrap-inner {
                width: 100%;
            }
        }
    }

    // States
}
