.main-category-floater {
    // Vars

    // Support

    // Module
    & {
        .main-category-floater--btn {
            cursor: pointer;
            display: flex;
            flex-flow: row nowrap;
            justify-content: center;
            align-items: center;
            padding: 9px 14px 8px;
            border-radius: 99px;
            color: $c--monochrome-white;
            transition: $trs--common-props;
        }

        .main-category-floater--text {
            @include ff--base-medium;
            position: relative;
            font-size: 1.6rem;
            transform: translateY(1px);
        }

        .main-category-floater--icon {
            display: block;
            margin-left: 8px;
        }
    }

    // Facets
    & {
        @each $color-key, $color-value in $brands {
            &.main-category-floater__main-category-facet-#{$color-key} {
                .main-category-floater--btn {
                    background-color: $color-value;

                    @include has-focus(true) {
                        background-color: color-shade($color-value, 20%);
                    }
                }
            }
        }
    }

    // States
}
