.background-map {
    // Vars

    // Support

    // Module
    & {
        position: relative;
        transition: opacity $trs--base;

        .background-map--element {
            position: absolute !important; // stylelint-disable-line declaration-no-important
            top: 0;
            right: 0;
            width: 100%;
            height: 100%;
        }


        .background-map--visible-area {
            pointer-events: none;
            position: absolute;
            top: 0;
            right: 0;
            height: 100%;

            // NOTE: Verify the visible area calculation by adding a box-shadow:
            //box-shadow:
            //    0 0 0 8px rgba($c--signal-info, 0.2) inset,
            //    0 0 0 4px rgba($c--signal-info, 0.2) inset;
        }

        .background-map--main-category-floater {
            position: absolute;
            top: $sz--main-menu-height + 16px;
            left: 50%;
            transform: translateX(-50%);
        }

        // These styles are necessary to override inline styles by the `@google/markercluster` module
        .background-map--marker-cluster {
            /* stylelint-disable declaration-no-important */
            > img {
                width: 100%;
                height: 100%;
            }

            > div {
                color: $c--brand-gottfried !important;
                top: -2px !important;
                font-size: $fz--x-small !important;
            }
            /* stylelint-enable declaration-no-important */
        }

        .background-map--zoom-controls {
            position: absolute;
            bottom: 24px;
            right: 24px;
        }
    }

    // Facets
    & {
        &.background-map__is-hidden {
            opacity: 0;
        }
    }

    // States
}
