.page-map-app {
    // Vars

    // Support

    // Module
    & {
        @include at-root(html, '.ie11') {
            width: 100%;
            height: 100%;
        }

        .page-map-app--main-menu {
            flex: 0 0 auto;
            height: $sz--main-menu-height;
        }

        .page-map-app--background-map {
            z-index: z-index(background-map);
            flex: 1 0 auto;
        }

        .page-map-app--main-sidebar {
            z-index: z-index(main-sidebar);
            position: fixed;
            top: 0;
            left: 0;
            height: 100%;

            @include mq('>lg') {
                width: ($screen-xl-sidebar-min / 3);
            }

            @include mq(('>md' '<=lg')) {
                width: ($screen-lg-sidebar-min / 3);
            }

            @include mq('<=md') {
                width: 100%;
            }
        }

        .page-map-app--sub-sidebar {
            z-index: z-index(sub-sidebar);
            position: fixed;
            top: 0;
            height: 100%;

            @include mq('>lg') {
                left: ($screen-xl-sidebar-min / 3);
                width: ($screen-xl-sidebar-min / 3);
            }

            @include mq(('>md' '<=lg')) {
                left: ($screen-lg-sidebar-min / 3);
                width: ($screen-lg-sidebar-min / 3);
            }

            @include mq('<=md') {
                //position: relative;
                //height: auto;
                width: 100%;
            }
        }

        .page-map-app--home-overlay {
            @include overlay(fixed);
            z-index: z-index(home-overlay);
        }
    }

    // Facets

    // States
}
