////
/// List styles (ul, ol)
////

@mixin type--list {
    padding-left: $sz--list-padding;
    list-style: none;

    > li {
        position: relative;
        display: inline-block;
        width: 100%;

        > * { // stylelint-disable selector-max-universal, plugin/selector-bem-pattern
            margin-bottom: 0;
        }

        ~ li {
            margin-top: $sz--list-vertical-spacing;
        }

        &::before {
            position: absolute;
            top: 0;
            right: 100%;
            display: inline-block;
            width: $sz--list-padding;
            height: auto;
            font-size: inherit;

            color: currentColor;
        }
    }
}

@mixin type--ul {
    @include type--list;

    > li {
        &::before {
            content: '\2022';
            text-align: center;
        }
    }
}

@mixin type--ol {
    @include type--list;
    counter-reset: li;

    > li {
        &::before {
            content: counter(li) '.';
            counter-increment: li;
            text-align: center;
        }
    }
}
