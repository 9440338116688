/// Computed breakpoints system, borrowed from bootstrap
///
$screen-xs-min: 0;
$screen-sm-min: 544px;
$screen-md-min: 768px;
$screen-lg-sidebar-min: 960px; // `lg-sidebar` min
$screen-lg-min: 992px;
$screen-xl-sidebar-min: 1200px; // `xl-sidebar` min
$screen-xl-min: 1280px;
$screen-xxl-min: 1600px;

$screen-xs-max: ($screen-sm-min - 1);
$screen-sm-max: ($screen-md-min - 1);
$screen-md-max: ($screen-lg-sidebar-min - 1);
$screen-lg-sidebar-max: $screen-lg-min - 1;
$screen-lg-max: ($screen-xl-sidebar-min - 1);
$screen-xl-sidebar-max: $screen-xl-min - 1;
$screen-xl-max: ($screen-xxl-min - 1);


/// Break point definitions used by `include-media`
///
$breakpoints: (
    xs: $screen-xs-max,
    sm: $screen-sm-max,
    md: $screen-md-max,
    lg-sidebar: $screen-lg-sidebar-max,
    lg: $screen-lg-max,
    xl-sidebar: $screen-xl-sidebar-max,
    xl: $screen-xl-max,
);

/// Media type expressions
///
$media-type-expressions: (
    screen: 'screen',
    print: 'print',
);

/// Media feature expressions
///
$media-feature-expressions: (
    width: (
        xs: '(max-width: #{$screen-xs-max})',
        sm: ('(min-width: #{$screen-sm-min}) and (max-width: #{$screen-sm-max})'),
        md: ('(min-width: #{$screen-md-min}) and (max-width: #{$screen-md-max})'),
        lg-sidebar: ('(min-width: #{$screen-lg-sidebar-min}) and (max-width: #{$screen-lg-sidebar-max})'),
        lg: ('(min-width: #{$screen-lg-min}) and (max-width: #{$screen-lg-max})'),
        xl-sidebar: ('(min-width: #{$screen-xl-sidebar-min}) and (max-width: #{$screen-xl-sidebar-max})'),
        xl: ('(min-width: #{$screen-xl-min}) and (max-width: #{$screen-xl-max})'),
        xxl: '(min-width: #{$screen-xxl-min})',
    ),
    orientation: (
        portrait: '(orientation: portrait)',
        landscape: '(orientation: landscape)',
    ),
    resolution: (
        res2x: ('(min-resolution: 2dppx)'),
        res3x: ('(min-resolution: 3dppx)'),
    ),
);

/// Media expressions, used by `include-media`
/// @example - Combine width expression `xs` with resolution expression `res2x`:
/// ```scss
///     @include media('xs', 'res2x') { color: red; }
/// ```
///
$media-expressions: map-assign(
    $media-type-expressions,
    map-get($media-feature-expressions, width),
    map-get($media-feature-expressions, orientation),
    map-get($media-feature-expressions, resolution)
);

/// Include mq spy
///
@include mq--spy('.u-mq-spy');
