.decorative-elements {
    // Vars
    $sz--padding-outside: 24px;

    // Support

    // Module
    & {
        .decorative-elements--snake-pattern {
            margin: 36px auto;
        }

        .decorative-elements--spacer {
            display: flex;
            margin: 20px auto $sz--padding-outside;
        }

        .decorative-elements--spacer-icon {
            display: flex;
            margin: auto;
            color: $c--brand-gottfried;
        }

        .decorative-elements--cta {
            display: inline-flex;
            margin: 0 auto 30px;
        }
    }

    // Facets
    & {
        // In CMS detail
        &.decorative-elements__in-cms {
            .decorative-elements--cta {
                margin-bottom: 0;
            }
        }

        // In home overlay
        &.decorative-elements__in-home-overlay {
            .decorative-elements--snake-pattern {
                margin-top: 32px;
            }
        }
    }

    // States
}
