// stylelint-disable property-no-vendor-prefix, plugin/selector-bem-pattern

////
/// Form helper classes mostly for general input resets
////

/// Hide IE10 input decorations
///
input::-ms-clear,
input::-ms-reveal {
    display: none;
}

/// Hide IE10 select arrow decorations
///
select::-ms-expand {
    display: none;
}

/// Hide Chrome search-field decorations
///
input::-webkit-search-decoration,
input::-webkit-search-cancel-button,
input::-webkit-search-results-button,
input::-webkit-search-results-decoration {
    display: none;
}

/// Stop Android stock browser touch highlighting
///
* { // stylelint-disable-line selector-max-universal
    -webkit-focus-ring-color: rgba(255, 255, 255, 0);
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
    // Additionally needed for Nexus5/Chrome and Kindle Fire HD 7″:
    -webkit-tap-highlight-color: transparent; // stylelint-disable-line declaration-block-no-duplicate-properties
    outline: none;
}

/// Reset `input` and other default styles
///
input, textarea, keygen, select, button {
    background: none;
    color: inherit;
    cursor: inherit;
    font: inherit;
    line-height: normal;
    overflow: visible;
    padding: 0;
    border: 0;
    margin: 0;
}

/// Remove iPad and Firefox mobile top `input` shadow
///
input[type='text'],
input[type='email'],
input[type='search'],
input[type='password'],
input[type='submit'],
input[type='reset'],
input[type='button'],
button,
select {
    -moz-appearance: none; /* Firefox mobile */
    -webkit-appearance: none; /* iPad */
}

/// Remove Webkit and Blink number input spinners
/// @link https://css-tricks.com/snippets/css/turn-off-number-input-spinners/
///
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/// Remove Chrome `border-radius` for select
///
select {
    border-radius: 0;
}

/// Remove Firefox special padding
///
input::-moz-focus-inner,
button::-moz-focus-inner {
    border: 0;
    padding: 0;
}

/// Make `a` like a button
///
[role='button'] {
    // TODO: Review, what aspects are needed here
}
