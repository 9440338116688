.category-menu-btn {
    // Vars

    // Support

    // Module
    & {
        position: relative;
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        background-color: $c--brand-gottfried;

        .category-menu-btn--image {
            display: inline-block;
            width: auto;
            height: 100%;
        }

        .category-menu-btn--content {
            @include overlay;
            display: flex;
            flex-flow: column nowrap;
            justify-content: center;
            align-items: center;
            color: $c--monochrome-white;
        }

        .category-menu-btn--title {
            @include type--cta;
        }

        .category-menu-btn--snake-pattern {
            margin-top: 16px;
            color: $c--monochrome-white;
        }

        .category-menu-btn--tile-icon {
            position: absolute;
            bottom: 0;
            right: 0;
            padding: 8px;
            color: $c--monochrome-white;
        }
    }

    // Facets
    & {
        &.category-menu-btn__menu-opener {
            padding: 12px;

            .category-menu-btn--title {
                @include type--cta;
            }

            .category-menu-btn--image {
                display: inline-block;
                width: auto;
                height: 100%;
            }

            .category-menu-btn--snake-pattern {
                display: none;
            }
        }

        &.category-menu-btn__category-chooser {
            border-radius: 3px;
            padding: 12px;

            @include mq('>md') {
                box-shadow: $shadow-level-1;
                transition: box-shadow $trs--home-overlay;

                @include has-focus(true) {
                    box-shadow: $shadow-level-2;
                }
            }

            @include mq('<=md') {
                border: 6px solid $c--monochrome-white;
            }

            .category-menu-btn--title {
                @include type--cta-small;
            }

            .category-menu-btn--image {
                display: block;
                width: 100%;
                height: 100%;

                @include mq('<=md') {
                    width: auto;
                    height: auto;
                    max-width: 100%;
                    max-height: 100%;
                    margin: 0 auto;
                }
            }

            .category-menu-btn--tile-icon {
                display: none;
            }
        }

        // In `home-overlay`
        &.category-menu-btn__home-overlay {
            width: 100%;
            height: 100%;
            border-radius: 2px;
            box-shadow: $shadow-level-1;
            transition: box-shadow $trs--home-overlay;

            @include has-focus(true) {
                box-shadow: $shadow-level-2;
            }

            .category-menu-btn--image {
                width: 100%;
                height: 100%;
                max-width: 160px;
                max-height: 160px;

                @include mq('lg') {
                    max-width: 160px;
                    max-height: 160px;
                }

                @include mq('>xl') {
                    max-width: 300px;
                    max-height: 300px;
                }
            }

            .category-menu-btn--tile-icon {
                display: none;
            }
        }

        // In `home-overlay` and `type-root`
        &.category-menu-btn__home-overlay.category-menu-btn__type-root {
            .category-menu-btn--image {
                max-width: 200px;
                max-height: 200px;

                @include mq('lg') {
                    max-width: 200px;
                    max-height: 200px;
                }

                @include mq('>xl') {
                    max-width: 400px;
                    max-height: 400px;
                }
            }
        }

        // Color facets
        @each $color-key, $color-value in $brands {
            &.category-menu-btn__main-category-facet-#{$color-key} {
                background-color: $color-value;
            }
        }
    }

    // States
}
