/// Secondary
///
@mixin btn--ternary {
    // Vars

    // Support

    // Module

    // Facets

    // States
    & {
        // Base
        @include btn--apply-states(()) {
            @include btn--ternary-base;
        }

        // Focus
        @include btn--apply-states(('disabled': false, 'focus': true)) {
            @include btn--ternary-focus;
        }

        // Active
        @include btn--apply-states(('focus': default, 'active': true)) {
            @include btn--ternary-active;
        }

        // Disabled
        @include btn--apply-states(('disabled': true, 'focus': default, 'active': default)) {
            @include btn--ternary-disabled;
        }
    }
}

/// Base
///
@mixin btn--ternary-base {
    color: $c--label-text;
    background-color: $c--monochrome-white;
    border-color: $c--monochrome-white;
}

/// Focus
///
@mixin btn--ternary-focus {
    color: $c--brand-gottfried;
    background-color: $c--monochrome-paper-white;
    border-color: $c--monochrome-paper-white;
}

/// Active
///
@mixin btn--ternary-active {
    background-color: $c--monochrome-light-gray;
    border-color: $c--monochrome-light-gray;
}

/// Disabled
///
@mixin btn--ternary-disabled {
    color: $c--monochrome-light-gray;
    background-color: $c--monochrome-white;
    border-color: $c--monochrome-white;
}
