.main-sidebar {
    // Vars

    // Support

    // Module
    & {
        padding-top: $sz--main-menu-height;
        background-color: $c--monochrome-white;
        background-image: linear-gradient(to left, $c--monochrome-light-gray 0, transparent 1px, transparent 2px);

        .main-sidebar--scroll-container {
            overflow-x: hidden;
            overflow-y: auto;
            width: 100%;
            height: 100%;

            //@include mq('<=md') {
            //    overflow: hidden;
            //    height: auto;
            //}
        }
    }

    // Facets

    // States
}
