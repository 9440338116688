.page-cms-category-detail {
    // Vars

    // Support

    // Module
    & {
        text-align: center;

        .page-cms-category-detail--subtitle {
            margin-bottom: 36px;
        }

        .page-cms-category-detail--submenu {
            text-align: left;
        }
    }

    // Facets

    // States
}
