.page-cms-home {
    // Vars

    // Support

    // Module
    & {
        text-align: center;

        .page-cms-home--spacer {
            margin-top: 36px;
        }

        .page-cms-home--subtitle {
            margin-bottom: 36px;
        }

        .page-cms-home--subnav {
            ~ .page-cms-home--subnav {
                margin-top: 36px;
            }
        }
    }

    // Facets

    // States
}
