@function snake-pattern--create-svg($color) {
    $svg-data: '<path fill="#{$color}" d="M5.00004 0.292847L9.00004 4.29285L13 0.292847L17 4.29285L21 0.292847L25 4.29285L29 0.292847L33 4.29285L37 0.292847L41 4.29285L45 0.292847L49 4.29285L52.6465 0.6464L53.3536 1.35351L49 5.70706L45 1.70706L41 5.70706L37 1.70706L33 5.70706L29 1.70706L25 5.70706L21 1.70706L17 5.70706L13 1.70706L9.00004 5.70706L5.00004 1.70706L1.35359 5.35351L0.646484 4.6464L5.00004 0.292847Z"/>';
    @return svg--create($sz--snake-pattern-width / 1px, $sz--snake-pattern-height / 1px, $svg-data);
}

@mixin snake-pattern--create-background($color: $c--label-text) {
    background-repeat: repeat-x;
    background-position: center center;
    background-color: transparent;
    background-image: url('#{snake-pattern--create-svg($color)}');
}
