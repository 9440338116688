/// Medium
///
@mixin btn--size-medium {
    padding: 15px 32px 10px;
    font-size: $fz--medium;
}

/// Small
///
@mixin btn--size-small {
    padding: 9px 10px 7px;
    font-size: $fz--small;
}

/// Medium bubble
///
@mixin btn--size-bubble-medium {
    padding: 8px;
}

/// Small bubble
///
@mixin btn--size-bubble-small {
    padding: 4px;
}

/// Medium square
///
@mixin btn--size-square-medium {
    padding: 8px;
}

/// Small square
///
@mixin btn--size-square-small {
    padding: 4px;
}
