.sub-sidebar {
    // Vars

    // Support

    // Module
    & {
        padding-top: $sz--main-menu-height;
        background-color: $c--monochrome-white;

        .sub-sidebar--transition-wrapper {
            width: 100%;
            height: 100%;
        }

        .sub-sidebar--scroll-container {
            overflow-x: hidden;
            overflow-y: auto;
            width: 100%;
            height: 100%;

            //@include mq('<=md') {
            //    overflow: hidden;
            //    height: auto;
            //}
        }

        .sub-sidebar--content {
            width: 100%;
        }

        .sub-sidebar--loader {
            @include overlay;
            display: flex;
            flex-flow: row nowrap;
            justify-content: center;
            align-items: center;
            color: $c--brand-gottfried;
        }

        .sub-sidebar--loader-element {
            @include at-root('.ie11') {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }
    }

    // Facets

    // States
}
