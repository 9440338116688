/// Field styles
///
@mixin form-field--primary-textarea {
    // Vars
    $sz--min-height: 50px;

    // Module
    & {
        .form-field--input {
            max-width: 100%;
            min-height: $sz--min-height;
            resize: vertical;
            -ms-overflow-style: -ms-autohiding-scrollbar;
        }
    }
}
