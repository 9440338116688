.skel {
    // Vars

    // Support

    // Module
    & {
        display: flex;
        flex-flow: column nowrap;
        width: 100%;
        min-height: 100%;
        overflow: hidden;

        .skel--main {
            display: flex;
            flex-flow: column nowrap;
            flex: 1 0 auto;
        }
    }

    // Facets
    & {
        // CMS
        &.skel__cms {
            overflow: auto;
        }

        // Map app
        &.skel__map-app {
            @include at-root(html, '.ie11') {
                &,
                .skel--main {
                    display: block;
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }

    // States
}
