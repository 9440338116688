.snake-pattern {
    // Vars

    // Support

    // Module
    & {
        display: block;
        width: $sz--snake-pattern-width;
        height: $sz--snake-pattern-height;
    }

    // Facets

    // States
}
