// stylelint-disable plugin/selector-bem-pattern
@import 'typography__heading-types';
@import 'typography__content-types';
@import 'typography__list-types';

/// Basic typographic styling
///
html {
    font-size: 62.5%;
}

body {
    @include ff--deco-regular;
    @include type--copy;
    color: $c--label-text;
}

.skel {
    /// Heading types
    ///
    h1:not(.u-reset),
    .u-reset__h1 {
        @include type--h1;
    }

    h2:not(.u-reset),
    .u-reset__h2 {
        @include type--h2;
    }

    h3:not(.u-reset),
    .u-reset__h3 {
        @include type--h3;
    }

    h4:not(.u-reset),
    .u-reset__h4 {
        @include type--h4;
    }

    h5:not(.u-reset),
    .u-reset__h5 {
        @include type--h5;
    }

    h6:not(.u-reset),
    .u-reset__h6 {
        @include type--h6;
    }

    /// Content types
    ///
    p:not(.u-reset),
    .u-reset__p {
        @include type--copy;
    }

    /// List types
    ///
    ul, ol {
        &.u-reset { // stylelint-disable-line selector-no-qualifying-type
            &, > li {
                list-style: none;
            }
        }
    }

    ul:not(.u-reset),
    .u-reset__ul {
        @include type--ul;
    }

    ol:not(.u-reset),
    .u-reset__ol {
        @include type--ol;
    }

    /// Tags with visual implication
    ///
    b, strong {
        font-weight: $fw--bold;
    }

    i, em {
        font-style: italic;
    }

    sub {
        font-size: $fz--x-small;
        vertical-align: sub;
    }

    sup {
        font-size: $fz--x-small;
        vertical-align: super;
    }

    /// Simple link
    ///
    a, .u-link {
        color: currentColor;
        text-decoration: none;

        &:not(.u-reset):not(.btn) {
            @include has-focus(false) {
                color: $c--label-link;
                text-decoration: none;
            }

            @include has-focus(true) {
                color: color-shade($c--brand-gottfried, 20%);
                text-decoration: underline;
            }
        }
    }

    /// Misc tags and definitions
    ///
    hr {
        @include snake-pattern--create-background($c--label-text);
        display: block;
        border: 0;
        width: $sz--snake-pattern-width;
        height: $sz--snake-pattern-height;
        color: inherit; // NOTE: Firefox (v52) needs `color: inherit` to properly display `currentColor`
    }

    /// Preformatted text (code)
    ///
    pre, code {
        font-family: monospace;
    }
}
