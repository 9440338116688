////
/// Header styles (hX)
////
@mixin type--jumbotron {
    @include ff--base-medium;
    font-size: 4.4rem;
    line-height: (52px / 44px);

    @include mq('<=sm') {
        font-size: 3.2rem;
        line-height: (38px / 32px);
    }
}

@mixin type--h1 {
    @include ff--base-medium;
    font-size: 2.8rem;
    line-height: (33px / 28px);
}

@mixin type--h2 {
    @include ff--base-medium;
    font-size: 2.0rem;
    line-height: (24px / 20px);
}

@mixin type--h3 {
    @include ff--base-medium;
    font-size: 1.6rem;
    line-height: (19px / 16px);
}

@mixin type--h4 {
    //
}

@mixin type--h5 {
    //
}

@mixin type--h6 {
    //
}
