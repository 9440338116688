.main-menu {
    // Vars

    // Support

    // Module
    & {
        @include grid--root((row: 'items', cols: ('item')));
        @include grid--space((gutter: (16px, 16px), row: 'items', cols: ('item')));
        position: relative;

        .main-menu--background {
            @include overlay;
            z-index: z-index(main-menu);
            background-color: $c--monochrome-white;
            transition: background-color $trs--base;
        }

        .main-menu--list {
            position: relative;
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            width: 100%;
            height: 100%;
            padding: 0 24px;
        }

        .main-menu--items {
            display: flex;
            flex-flow: row nowrap;
            flex: 1 0 auto;
            justify-content: space-between;
        }

        .main-menu--item.main-menu--item__home-link {
            z-index: z-index(home-link);
        }

        .main-menu--mobile-switcher {
            z-index: z-index(mobile-switcher);
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
        }

        .main-menu--hamburger {
            z-index: z-index(hamburger);
            position: absolute;
            top: 0;
            right: 0;
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
        }

        .main-menu--home-link {
            display: block;
            transition: $trs--common-props;
        }

        .main-menu--home-image {
            display: block;
            width: 32px;
            height: 31px;
        }
    }

    // Facets
    & {
        &.main-menu__in-cms {
            &.main-menu__is-active {
                .main-menu--background {
                    background-color: transparent;
                }
            }
        }
    }

    // States
    & {
        // Home link focus
        & {
            .main-menu--home-link {
                @include has-focus(true) {
                    color: $c--brand-gottfried;
                }
            }
        }

        // Has active menu sidebar
        &.main-menu__is-active {
            @include mq('<=md') {
                .main-menu--home-link {
                    color: $c--monochrome-white;

                    @include has-focus(true) {
                        color: $c--monochrome-white;
                        opacity: 0.8;
                    }
                }
            }
        }

        & {
            @include mq('<=md') {
                z-index: z-index(main-menu-mobile);
                position: fixed;
                top: 0;
                left: 0;
                width: 100%;

                &.main-menu__is-active {
                    .main-menu--background {
                        background-color: transparent;
                    }
                }
            }
        }
    }
}
