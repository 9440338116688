@mixin form-field--primary-list() {
    // Vars
    $sz--list-gutter-x: 20px;
    $sz--list-gutter-y: 8px;

    // Module
    & {
        @include grid--root((cols: ('input-container')));
        @include grid--space((gutter: ($sz--list-gutter-y, $sz--list-gutter-x), cols: ('input-container')));

        .form-field--list-item {
            width: 100%;
        }
    }

    // Facets
    & {
        &.form-field__vertical {
            .form-field--row {
                flex-flow: column nowrap;
            }
        }
    }
}
