////
/// Content types (p)
////
@mixin type--cta-large {
    $fz: 2.8rem;

    @include ff--base-bold;
    font-size: $fz;
    line-height: (3.3rem / $fz);
    letter-spacing: 0.1rem;
    text-transform: uppercase;
}

@mixin type--cta {
    $fz: 2.0rem;

    @include ff--base-bold;
    font-size: $fz;
    line-height: (2.4rem / $fz);
    letter-spacing: 0.1rem;
    text-transform: uppercase;
}

@mixin type--cta-small {
    $fz: 1.6rem;

    @include ff--base-bold;
    font-size: $fz;
    line-height: (1.9rem / $fz);
    letter-spacing: 0.1rem;
    text-transform: uppercase;
}

@mixin type--lead {
    $fz: 2.2rem;

    @include ff--deco-regular-italic;
    font-size: $fz;
    line-height: (3.1rem / $fz);
}

@mixin type--copy {
    @include ff--deco-regular;
    font-size: $fz--medium;
    line-height: $lh--medium;
}

@mixin type--copy-small {
    @include ff--deco-regular;
    font-size: $fz--small;
    line-height: $lh--small;
}
