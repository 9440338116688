.form-submit {
    // Vars

    // Support

    // Module
    & {
        display: flex;
        flex-flow: column nowrap;
        justify-content: flex-end;
        width: 100%;

        .form-submit--wrap {
            display: flex;
            flex-flow: column nowrap;
            align-items: flex-end;
            width: 100%;
        }

        .form-submit--general-errors {
            margin-top: 8px;
        }

        .form-submit--recaptcha-notice {
            display: block;
            margin-top: 8px;
            font-size: $fz--x-small;
            line-height: $lh--x-small;
        }
    }

    // Facets

    // States
}
