.swiper-gallery {
    // Vars
    $image-description-height: 40px;
    $image-description-margin-top: 16px;

    // Support

    // Module
    & {
        position: relative;

        .swiper-gallery--buttons {
            pointer-events: none;
            z-index: 1;
            position: absolute;
            top: 0;
            right: 0;
            width: 100%;
        }

        .swiper-gallery--buttons-inner {
            @include overlay;
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            justify-content: space-between;
        }

        .swiper-gallery--btn {
            pointer-events: auto;
        }

        .swiper-gallery--btn-content {
            pointer-events: none;
        }

        .swiper-gallery--figure {
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 100%;
        }

        .swiper-gallery--ratio-wrap-outer {
            position: relative;
            width: 100%;
            padding-top: 100% * 9 / 16;
        }

        .swiper-gallery--ratio-wrap-inner {
            @include overlay;
            text-align: center;
        }

        .swiper-gallery--image-button {
            @include overlay;
        }

        .swiper-gallery--image {
            display: inline-flex;
            max-width: 100%;
            max-height: 100%;

            @include at-root(html, '.ie11') {
                position: relative;
                transform: translateX(-50%);
                left: 50%;
            }

            @supports (object-fit: contain) {
                object-fit: contain;
                width: 100%;
                height: 100%;
            }
        }

        .swiper-gallery--open-gallery {
            z-index: 1;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            opacity: 0;
            transition: opacity $trs--base;
            box-shadow: $shadow-level-1;
            border: 2px solid transparent;
            box-sizing: border-box;

            > svg {
                width: 16px;
                height: 16px;
            }

            @include btn--secondary-base;
            @include btn--square;
            @include btn--size-bubble-medium;
        }

        .swiper-gallery--description {
            margin-top: 12px;
            width: 100%;
            font-size: $fz--small;
            text-align: center;
        }

        a.swiper-gallery--conditional-link { // stylelint-disable-line selector-no-qualifying-type
            @include ff--deco-regular-italic;
            transition: $trs--common-props;

            @include has-focus(true) {
                color: $c--brand-gottfried;
            }
        }
    }

    // Facets
    & {
        .swiper-gallery__cms-plugin {
            width: 100%;
        }
    }

    // States
    & {
        // Open overlay btn
        button.swiper-gallery--image-button { // stylelint-disable selector-no-qualifying-type
            cursor: pointer;

            @include has-focus(true) {
                .swiper-gallery--open-gallery {
                    opacity: 1;
                }
            }
        }

        // Disabled swiper btn
        .swiper-gallery--btn {
            &.swiper-gallery--btn__disabled {
                color: $c--monochrome-light-gray;
                cursor: default !important; // stylelint-disable-line declaration-no-important
            }
        }
    }
}
