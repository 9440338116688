////
/// Form field facets and state generation library
////

/// Dependencies and components
///
@import 'form-field__helpers';
@import 'form-field__states';
@import 'form-field__facets';

/// Form field facets
///
.form-field {
    // Vars

    // Support

    // Module

    // Facets
    &.form-field__base {
        @include form-field--primary;
    }

    // States
}
