&.grid__cms {
    // Vars
    $scrollbar-offset: 16px;
    $grid--cms-container-sizes: $grid--base-container-sizes !global;
    $grid--cms-spacing: (
        (mq: 'xs', gutter: (36px 16px), edge: 16px),
        (mq: 'sm', gutter: (36px 20px), edge: 20px),
        (mq: 'md', gutter: (36px 24px), edge: 24px),
        (mq: 'lg-sidebar', gutter: (36px 32px), edge: 32px),
        (mq: 'lg', gutter: (36px 32px), edge: 32px),
        (mq: 'xl-sidebar', gutter: (36px 36px), edge: 36px),
        (mq: 'xl', gutter: (36px 36px), edge: 36px),
        (mq: 'xxl', gutter: (40px 40px), edge: 40px),
    ) !global;

    // Support

    // Module
    & {
        @include grid--root;

        @each $value in $grid--cms-container-sizes {
            @include grid--constrain($value);
        }

        @each $value in $grid--cms-spacing {
            @include grid--space($value);
        }

        .grid--container {
            margin-top: 0;
            margin-bottom: 0;

            &.grid--container__fluid {
                max-width: none;
            }
        }

        .grid--col {
            &.grid--col__cms-plugin {
                @include grid--span((ratio: 1 / 1));
            }
        }
    }

    // Facets

    // States
}
