.zoom-controls {
    // Vars

    // Support

    // Module
    & {
        @include grid--root((row: 'items', cols: ('item')));
        @include grid--space((gutter: 0, row: 'items', cols: ('item')));
        background-color: $c--monochrome-white;
        border-radius: 2px;
        box-shadow: $shadow-level-1;

        &::after {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            display: block;
            width: 32px;
            height: 1px;
            background-color: $c--monochrome-light-gray;
        }

        .zoom-controls--items {
            flex-flow: column nowrap;
        }
    }

    // Facets

    // States
}
