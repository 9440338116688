/// Button with an internal grid
///
@mixin btn--has-grid {
    display: inline-flex;
    align-items: center;
    justify-content: center;

    @include grid--root((
        row: 'wrap',
        cols: ('icon', 'text'),
    ));
    @include grid--space((
        gutter: 8px,
        row: 'wrap',
        cols: ('icon', 'text'),
    ));

    .btn--wrap {
        display: inline-flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: center;
    }

    .btn--text {
        // NOTE: Depending on the font, we need a correction value to visually center our text:
        position: relative;
        transform: translateY(-1px);
        text-align: left;
    }
}
