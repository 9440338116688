.cms-works-menu {
    // Vars

    // Support

    // Module
    & {
        .cms-works-menu--item {
            ~ .cms-works-menu--item {
                margin-top: 16px;
            }
        }

        .cms-works-menu--link {
            $fz: 1.6rem;

            @include ff--base-medium;
            display: inline-flex;
            border-bottom: 1px solid currentColor;
            font-size: $fz;
            line-height: (1.9rem / $fz);
            text-transform: none;
            text-decoration: none;
            color: $c--brand-gottfried;
            transition: $trs--common-props;

            @include has-focus(true) {
                color: color-shade($c--brand-gottfried, 20%);
            }
        }
    }

    // Facets

    // States
}
