.category-display {
    // Vars
    $sz--arrow-offset: 20px;

    // Support

    // Module
    & {
        .category-display--head {
            display: block;
            height: $sz--entry-teaser-height;
            padding: 12px;

            @include has-focus(false) {
                .category-display--arrow {
                    opacity: 0;
                    transform: translateX(-$sz--arrow-offset);
                }
            }

            @include has-focus(true) {
                .category-display--arrow {
                    opacity: 1;
                    transform: translateX(0);
                }
            }
        }

        .category-display--head-content {
            position: relative;
            display: flex;
            flex-flow: row nowrap;
            justify-content: center;
            align-items: flex-end;
            height: 100%;
        }

        .category-display--entry-teaser {
            border-bottom: 1px solid $c--monochrome-light-gray;

            &:first-child {
                border-top: 1px solid $c--monochrome-light-gray;
            }
        }

        .category-display--title {
            @include type--lead;
        }

        .category-display--arrow {
            position: absolute;
            bottom: 6px;
            right: 0;

            display: flex;
            flex-flow: row nowrap;
            justify-content: center;
            align-items: center;
            padding-left: 12px;
            visibility: hidden;
            color: $c--brand-gottfried;

            transition: transition-props($trs--base, transform, opacity);
        }
    }

    // Facets
    & {
        // Colors
        @each $color-key, $color-value in $brands {
            &.category-display__main-category-facet-#{$color-key} {
                .category-display--title {
                    color: $color-value;
                }
            }
        }

        // Root category
        &.category-display__main-category-facet-gottfried {
            .category-display--head {
                display: none;
            }
        }

        // Has link
        &.category-display__has-category-link {
            .category-display--title {
                @include type--h2;
            }

            .category-display--arrow {
                visibility: visible;
            }
        }

        // In CMS
        &.category-display__in-cms {
            .category-display--head {
                display: none;
            }
        }
    }

    // States
}
