/// Font-styles, usable for project-wide styling of text instances
///
// TODO: Define base-stack and style properties
@mixin ff--base-medium($ff-definition: $ff--base-stack) {
    font-family: $ff-definition;
    font-weight: $fw--medium;
    font-style: normal;
}

@mixin ff--base-bold($ff-definition: $ff--base-stack) {
    font-family: $ff-definition;
    font-weight: $fw--bold;
    font-style: normal;
}

@mixin ff--deco-regular($ff-definition: $ff--deco-stack) {
    font-family: $ff-definition;
    font-weight: $fw--normal;
    font-style: normal;
}

@mixin ff--deco-regular-italic($ff-definition: $ff--deco-stack) {
    font-family: $ff-definition;
    font-weight: $fw--normal;
    font-style: italic;
}
