.home-overlay {
    // Vars
    $sz--padding-outside: 24px;
    $sz--category-width: 212px;
    $sz--category-width-large: 424px;
    $sz--category-list-width: (2 * $sz--category-width) + (3 * $sz--padding-outside);
    $sz--category-list-width-large: (2 * $sz--category-width-large) + (3 * $sz--padding-outside);

    // Support

    // Module
    & {
        width: 100%;

        .home-overlay--wrapper {
            @include grid--root((row: 'category-items', cols: ('category-item')));
            @include grid--space((gutter: ($sz--padding-outside, $sz--padding-outside), row: 'category-items', cols: ('category-item')));
            display: flex;
            flex-flow: row nowrap;
            width: 100%;
            min-height: 100%;
            padding-top: $sz--main-menu-height;
            background-color: $c--monochrome-white;

            @include mq('<=md') {
                flex-flow: row wrap;
            }
        }

        .home-overlay--category-wrapper {
            width: 100%;

            @include mq('>md') {
                display: flex;
                flex-flow: row nowrap;
                align-items: stretch;
            }

            @include at-root(html, '.ie11') {
                display: block;
            }
        }

        .home-overlay--category-list {
            display: flex;
            flex-flow: row nowrap;
            justify-content: center;
            align-items: stretch;
            flex: 0 0 $sz--category-list-width;
            padding: 0 $sz--padding-outside $sz--padding-outside;

            @include mq('<=md') {
                order: 1;
                flex: 0 1 100%;
                justify-content: stretch;
                margin: 0 auto;
                max-width: $sz--category-list-width;
            }

            @include mq('>xl') {
                @include grid--span((ratio: 1 / 3));
                max-width: $sz--category-list-width-large;
            }
        }

        .home-overlay--category-items {
            flex-flow: row wrap;
            will-change: flex;

            @include at-root(html, '.ie11') {
                //
            }
        }

        .home-overlay--category-item {
            @include grid--span((ratio: 1 / 2));
            overflow: visible;

            &.home-overlay--category-item__is-root-category {
                @include grid--span((ratio: 1 / 1));
            }
        }

        .home-overlay--introduction {
            display: flex;
            flex: 0 1 100%;
            flex-flow: row nowrap;
            justify-content: center;
            align-items: center;

            @include mq('<=md') {
                order: 0;
                flex: 0 1 100%;
                padding: 36px $sz--padding-outside 60px;
            }
        }

        .home-overlay--content {
            text-align: center;

            @include mq('<=lg') {
                max-width: 420px;
            }

            @include mq('>lg') {
                max-width: 480px;
            }
        }

        .home-overlay--title {
            @include type--jumbotron;
        }

        .home-overlay--text {
            @include type--copy;
        }

        .home-overlay--cta {
            display: block;
            margin: 0 auto 30px;
        }

        .home-overlay--cta-btn {
            display: inline-block;
            margin: auto;
        }

        .home-overlay--secondary-action-btn {
            @include ff--base-medium;
            border-bottom: 1px solid currentColor;
            color: currentColor;
            text-decoration: none;
            transition: $trs--common-props;

            &:not(.u-reset):not(.btn) {
                @include has-focus(false) {
                    text-decoration: none;
                }

                @include has-focus(true) {
                    text-decoration: none;
                }
            }
        }

        .home-overlay--category-menu-btn {
            padding: 12px;
        }
    }

    // Facets

    // States
}
