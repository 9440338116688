.entry-content {
    // Vars
    $sz--content-spacing: 36px;

    // Support
    @mixin entry-content--zigzag($height, $color) {
        $width: $height * 2;

        content: '';
        position: absolute;
        left: 0;
        display: block;
        width: 100%;
        height: $width;
        background: linear-gradient(-45deg, $color $height, transparent 0), linear-gradient(45deg, $color $height, transparent 0);
        background-position: left-bottom;
        background-repeat: repeat-x;
        background-size: $width $width;
    }

    // Module
    & {
        // Main elements
        .entry-content--header {
            width: 100%;
            padding: 0 $sz--content-spacing;
            text-align: center;
            overflow: auto;

            @include at-root(html, '.ie11') {
                overflow: visible;
            }
        }

        .entry-content--body {
            padding: $sz--content-spacing;
            overflow: auto;
        }

        .entry-content--excerpt {
            position: relative;
            padding: 24px $sz--content-spacing 60px;
            margin-bottom: 56px;
            background-color: $c--monochrome-paper-white;

            &::before,
            &::after {
                @include entry-content--zigzag(4px, $c--monochrome-paper-white);
                content: '';
                pointer-events: none;
            }

            &::before {
                bottom: 100%;
            }

            &::after {
                top: 100%;
                transform: rotateX(0.5turn);
            }
        }

        .entry-content--footer {
            padding: 0 $sz--content-spacing $sz--content-spacing;
        }

        // Detail elements
        .entry-content--cover-image {
            display: block;
            width: 100%;
            height: 300px;
            margin-bottom: $sz--content-spacing;

            @include at-root(html, '.ie11') {
                position: relative;
                height: auto;
                padding-top: (100% * 10 / 11);
            }
        }

        .entry-content--cover-image-element {
            width: 100%;
            height: 100%;
            object-fit: cover;

            @include at-root(html, '.ie11') {
                position: absolute;
                top: 50%;
                left: 50%;
                display: inline-block;
                font-size: 0;
                max-width: 100%;
                max-height: 100%;
                transform: translate(-50%, -50%);
            }
        }

        .entry-content--title {
            margin-bottom: 16px;
        }

        .entry-content--address {
            display: block;
            font-size: $fz--small;
            line-height: $lh--small;
        }

        .entry-content--snake-pattern {
            &.entry-content--snake-pattern__top {
                margin: 32px auto 0;
            }

            &.entry-content--snake-pattern__bottom {
                margin: 0 auto $sz--content-spacing;
            }
        }

        .entry-content--text {
            margin-bottom: $sz--content-spacing;
        }

        .entry-content--swiper-gallery {
            //
        }

        .entry-content--excerpt-text {
            &.entry-content--excerpt-text__center {
                text-align: center;
            }
        }

        .entry-content--excerpt-icon {
            display: flex;
            flex-flow: row nowrap;
            justify-content: center;
            margin-bottom: 12px;
            color: $c--brand-gottfried;
        }

        .entry-content--related-title {
            @include type--h1;
            text-align: center;
        }
    }

    // Facets

    // States
}
