/// Monochrome special decoration colors
///
$c--monochrome-white: #ffffff;
$c--monochrome-black: #000000;
$c--monochrome-black-gray: #202020;
$c--monochrome-dark-gray: #3c363c;
$c--monochrome-light-gray: #e0e0e0;
$c--monochrome-paper-white: #f2f2f2;

// NOTE: Not in use (only for styleguide)
$c--monochrome-silver: #bb00bb;

$monochromes: (
    'white': $c--monochrome-white,
    'black': $c--monochrome-black,
    'black-gray': $c--monochrome-black-gray,
    'dark-gray': $c--monochrome-dark-gray,
    'silver': $c--monochrome-silver,
    'light-gray': $c--monochrome-light-gray,
    'paper-white': $c--monochrome-paper-white,
);
/// Brand specific colors
///
$c--brand-gottfried: #0bb3bd;
$c--brand-persons: #fd6584;
$c--brand-works: #9087d7;
$c--brand-topics: #42d898;
$c--brand-hotspots: #66bfff;

$brands: (
    'gottfried': $c--brand-gottfried,
    'persons': $c--brand-persons,
    'works': $c--brand-works,
    'topics': $c--brand-topics,
    'hotspots': $c--brand-hotspots,
);


/// Single purpose colors
///
$c--label-text: #202020;
$c--label-link: $c--brand-gottfried;
$c--label-selection: null;

$labels: (
    'text': $c--label-text,
    'link': $c--label-link,
    'selection': $c--label-selection,
);


/// Signal colors
///
$c--signal-info: #0000ff;
$c--signal-error: #ff0000;
$c--signal-warning: #ffff00;
$c--signal-success: #008000;

$signals: (
    'info': $c--signal-info,
    'error': $c--signal-error,
    'warning': $c--signal-warning,
    'success': $c--signal-success,
);

/// Main color map
///
$color-maps: (
    monochrome: $monochromes,
    brand: $brands,
    label: $labels,
    signal: $signals,
);
