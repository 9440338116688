.hamburger-btn {
    // Vars

    // Support

    // Module
    & {
        cursor: pointer;
        position: relative;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        height: $sz--main-menu-height;
        transition: $trs--common-props;
        padding: 0 24px;

        @include has-focus(true) {
            opacity: 0.8;
        }

        .hamburger-btn--title {
            @include type--cta-small;
            position: relative;
            transform: translateY(2px); // Visually centers the text with the icon
            display: block;
            margin-right: 12px;
            text-transform: uppercase;

            @include mq('<=md') {
                display: none;
            }
        }

        .hamburger-btn--hamburger {
            display: flex;
        }
    }

    // Facets
    & {
        & {
            @include has-focus(true) {
                color: $c--brand-gottfried;
            }
        }

        &.hamburger-btn__is-active {
            color: $c--monochrome-white;

            @include has-focus(true) {
                color: $c--monochrome-white;
                opacity: 0.8;
            }
        }
    }

    // States
}
