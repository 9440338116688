.gallery-overlay {
    // Vars


    // Support

    // Module
    & {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        width: 100%;
        padding: $sz--main-menu-height 24px 0;
        background-color: $c--monochrome-white;

        .gallery-overlay--outer-wrapper {
            position: relative;
            width: 100%;
            height: 100%;
        }

        .gallery-overlay--inner-wrapper {
            @include overlay;
            z-index: 0;
            display: flex;
            flex-flow: row nowrap;
            justify-content: center;
            align-items: center;
        }

        .gallery-overlay--btn {
            z-index: 1;
            position: absolute;
            top: 0;
            right: -10px;
        }

        .gallery-overlay--element {
            width: 100%;
            padding-bottom: 24px;
        }
    }

    // Facets

    // States
}
