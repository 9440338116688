.cms-skeleton {
    // Vars

    // Support

    // Module
    & {
        .cms-skeleton--wrapper {
            width: 100%;
            max-width: 640px;
            margin: 0 auto;
        }

        .cms-skeleton--main-menu {
            z-index: z-index('cms-main-menu');
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: $sz--main-menu-height;
        }

        .cms-skeleton--content {
            z-index: 0;
            position: relative;
            padding-top: $sz--main-menu-height + 60px;
            padding-bottom: 120px;
        }
    }

    // Facets

    // States
}
