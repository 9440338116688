.loader-btn {
    // Vars

    // Support

    // Module
    & {
        position: relative;

        .loader-btn--content {
            transition: opacity $trs--base;
        }

        .loader-btn--loader {
            @include overlay;
            z-index: 1;
        }
    }

    // Facets

    // States
    & {
        // Toggle loader component
        &.loader-btn__is-loading {
            .loader-btn--content {
                opacity: 0;
            }
        }

        // Disabled
        &.loader-btn__is-disabled {
            // Styling this separately is necessary as vue has problems with root attributes, `disabled` in this case
        }
    }
}
