.page-cms-template {
    // Vars

    // Support

    // Module
    & {
        .page-cms-template--title {
            @include type--jumbotron;
            position: relative;
            transform: translateY(1.8rem);
            text-align: center;
        }

        .page-cms-template--plugins {
            text-align: left;
        }
    }

    // Facets

    // States
}
