/// Font weights used throughout the project
///
$fw--normal: 400;
$fw--medium: 500;
$fw--bold: 700;

/// The project's font families
///
$ff--base: 'BrandonText';
$ff--deco: 'Tisa';

/// Font stack definitions
/// @link http://www.cssfontstack.com/
///
// stylelint-disable value-keyword-case
$ff--base-stack: $ff--base, 'Helvetica Neue', Helvetica, Arial;
$ff--deco-stack: $ff--deco, 'Bodoni MT', Didot, 'Didot LT STD', 'Hoefler Text', Garamond, 'Times New Roman', serif;
// stylelint-enable value-keyword-case
